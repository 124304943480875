@charset "utf-8";
/* TOC – Color Variables

- Basics
- Corporate Identity Colorpalette
- Foundation Color Variables
- Grey Scale
- Topbar-Navigation
- Footer
- Code

*/






/* Corporate Identity Colorpalette
   https://color.adobe.com/de/Flat-Design-Colors-v2-color-theme-4341903/
------------------------------------------------------------------- */

$ci-1: #334D5C;		// dark turquoise
$ci-2: #45B29D;		// turquoise
$ci-3: #f4d93a;		// yellow
$ci-4: #Ea9586;		// orange
$ci-5: #bf5346;		// red
$ci-6: #c3cfa9;      // green

$ci-7: #fff2ce;
$ci-8: #281806;
$ci-9: #d7a72d;
$ci-10: #d4aa59;

/* Basics
------------------------------------------------------------------- */

$text-color:       	$ci-8;
$body-font-color: 	$text-color;
$body-bg: 			$ci-7;


/* Foundation Color Variables
------------------------------------------------------------------- */

$primary-color: $ci-1;
$secondary-color: $ci-6;
$alert-color: $ci-5;
$success-color: $ci-1;
$warning-color: $ci-4;
$info-color: $ci-1;



/* Grey Scale
------------------------------------------------------------------- */

$grey-1: #E4E4E4;
$grey-2: #D7D7D7;
$grey-3: #CBCBCB;
$grey-4: #BEBEBE;
$grey-5: #A4A4A4;
$grey-6: #979797;
$grey-7: #8B8B8B;
$grey-8: #7E7E7E;
$grey-9: #646464;
$grey-10: #575757;
$grey-11: #4B4B4B;
$grey-12: #3E3E3E;
$grey-13: #313131;
$grey-14: #242424;
$grey-15: #171717;
$grey-16: #0B0B0B;



/* Topbar-Navigation
------------------------------------------------------------------- */

$topbar-bg-color: $body-bg;
$topbar-bg: $topbar-bg-color;


$topbar-dropdown-toggle-color: $ci-1;

$topbar-link-color: $ci-8;
$topbar-link-color-hover: #ffffff;
$topbar-link-color-active: #ffffff;
$topbar-link-color-active-hover: $ci-8;

$topbar-dropdown-label-color: $ci-2;
$topbar-dropdown-link-bg-hover:$ci-1;

$topbar-link-bg-active: $ci-1; // Active Navigation Link
$topbar-link-bg-hover: $ci-1;
$topbar-link-bg-active-hover: $ci-1;


$topbar-dropdown-bg: $topbar-bg-color; // Background Mobile Navigation
$topbar-dropdown-link-color: $text-color;
$topbar-dropdown-link-bg: $topbar-bg-color;

$topbar-menu-link-color-toggled: $topbar-bg-color;
$topbar-menu-icon-color-toggled: $topbar-bg-color;
$topbar-menu-link-color: $text-color;
$topbar-menu-icon-color: $text-color;
$topbar-menu-link-color-toggled: $topbar-bg-color;
$topbar-menu-icon-color-toggled: $topbar-bg-color;



/* Footer
------------------------------------------------------------------- */

$footer-bg: $ci-10;
$footer-color: $ci-8;
$footer-link-color: $ci-7;


$subfooter-bg: $ci-1;
$subfooter-color: $ci-7;
$subfooter-link-color: $ci-8;



/* Code
------------------------------------------------------------------- */

$code-background-color: scale-color($secondary-color, $lightness: 70%);

$highlight-background: #ffffff;
$highlight-comment: #999988;
$highlight-error: #a61717;
$highlight-comment-special: #999999;
$highlight-deleted: #000000;
$highlight-error-2: #aa0000;
$highlight-literal-string: #d14;
$highlight-literal-number: #009999;
$highlight-name-attribut: #008080;
$highlight-error-background: #e3d2d2;
$highlight-generic-deleted: #ffdddd;
$highlight-generic-deleted-specific: #ffaaaa;
$highlight-generic-inserted: #ddffdd;
$highlight-generic-inserted-specific: #aaffaa;
$highlight-generic-output: #888888;
$highlight-generic-prompt: #555555;
$highlight-subheading: #aaaaaa;
$highlight-keyword-type: #445588;
$highlight-name-builtin: #0086B3;
$highlight-name-class: #445588;
$highlight-name-entity: #800080;
$highlight-name-exception: #990000;
$highlight-name-function: #990000;
$highlight-name-namespace: #555555;
$highlight-name-tag: #000080;
$highlight-text-whitespace: #bbbbbb;
$highlight-literal-string-regex: #009926;
$highlight-literal-string-symbol: #990073;
